import React from "react"

import { graphql } from "gatsby"
import styled from "styled-components"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import PostProvider from "context/PostContext"

import PostAuthorInfo from "./_components/PostAuthorInfo"
import PostContent from "./_components/PostContent"
import PostGeneralInfo from "./_components/PostGeneralInfo"
import PostHeader from "./_components/PostHeader"

const StyledPostContent = styled.div`
  display: grid;
  grid-gap: 3rem;
  align-items: start;
  padding: 5rem 0;
  grid-template-columns: minmax(240px, 1fr) auto minmax(240px, 1fr);
  ${({ theme }) => theme.mq.m} {
    max-width: ${({ theme }) => theme.container.m}px;
    grid-template-columns: 240px minmax(320px, auto);
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: auto;
    padding: 0;
  }
`

const Post = ({ location, data: { strapiPosts } }) => {
  const SEO = {
    title: strapiPosts.SEO?.title || strapiPosts.Title,
    description: strapiPosts.SEO?.description || "",
    image: strapiPosts.SEO?.image?.localFile?.url || "",
    keywords: strapiPosts.SEO?.keyword || "",
    url: strapiPosts.SEO?.url || "",
    seoPageName: strapiPosts.SEO?.page_name || "",
    author: strapiPosts.author ? `${strapiPosts.author.First_name} ${strapiPosts.author.Last_name}` : "",
  }

  return (
    <TransitionWrapper location={location}>
      <Seo {...SEO} />
      <PostProvider>
        <article style={{ background: "#fff" }}>
          <PostHeader
            author={strapiPosts.author}
            backgroundImage={strapiPosts.main_image.localFile}
            category={strapiPosts.category}
            title={strapiPosts.Title}
          />
          <StyledPostContent>
            <PostGeneralInfo readingTime={strapiPosts.reading_time} />
            <PostContent content={strapiPosts.Content} readingTime={strapiPosts.reading_time} />
            <PostAuthorInfo {...strapiPosts.author} />
          </StyledPostContent>
        </article>
        {/* {strapiPosts?.related_posts && strapiPosts.category.slug && (
          <PostRelatedArticles category={strapiPosts.category.slug} posts={strapiPosts.related_posts} />
        )} */}
      </PostProvider>
    </TransitionWrapper>
  )
}

export const PostEntityQuery = graphql`
  query PostEntityQuery($id: String!) {
    strapiPosts(id: { eq: $id }) {
      Title
      author {
        First_name
        Avatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Last_name
        Position
        linkedIn
        bio
      }
      Content
      category {
        Name
        color
      }
      main_image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      reading_time
      SEO {
        description
        keyword
        title
        url
        page_name
        image {
          localFile {
            url
          }
        }
      }
    }
  }
`
// put after reading time
// related_posts {
//         slug
//       }

Post.propTypes = {}

export default Post
