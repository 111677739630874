import React, { createContext, useState, useContext } from "react"

const PostContext = createContext(null)

const { Provider } = PostContext

const PostProvider = props => {
  const [progress, setProgress] = useState(null)

  const [intersectionQueue, setIntersectionQueue] = useState([])
  const [cursor, setCursor] = useState(0)

  const onIntersectIn = (id, freshIntersectionQueue) => {
    const intersectIndex = freshIntersectionQueue.indexOf(id)
    setCursor(intersectIndex)
  }

  const onIntersectOut = () => {
    setCursor(prev => prev - 1)
  }

  return (
    <Provider
      value={{
        intersectionQueue,
        setIntersectionQueue,
        cursor,
        setCursor,
        onIntersectOut,
        onIntersectIn,
        setProgress,
        progress,
      }}
      {...props}
    />
  )
}

export const usePostContext = () => useContext(PostContext)

export default PostProvider
