import React, { useEffect, useRef, memo, useCallback } from "react"

import python from "highlight.js/lib/languages/python"
import { throttle } from "lodash"
import Markdown from "marked-react"
import Lowlight from "react-lowlight"
import styled from "styled-components"

import "highlight.js/styles/gradient-dark.css"
import Button from "components/common/Button"
import urls from "config/urls"
import { usePostContext } from "context/PostContext"

import PostReadingTime from "./PostReadingTime"

const StyledPostReadingTimeWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.xs} {
    display: block;

    position: sticky;
    top: ${({ theme }) => theme.size.header}px;
    padding-top: 1rem;
    background-color: ${({ theme }) => theme.color.gray_100};
  }
`

const StyledContent = styled.main`
  font-size: 2rem;
  line-height: 3rem;

  ${({ theme }) => theme.mq.l} {
    max-width: 60ch;
  }

  ${({ theme }) => theme.mq.m} {
    max-width: unset;
    margin-right: 3rem;
  }

  ${({ theme }) => theme.mq.xs} {
    margin: 0 18px;
    padding: 0 2rem;
    max-width: 820px;
    margin: 0 auto;
  }

  p {
    text-align: center;
    margin-bottom: 1rem;
    text-align: left;

    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;
    }
  }

  pre {
    padding: 1rem;
    max-width: calc(100vw - 8rem);
    overflow-x: auto;
    font-size: 1.8rem;
    line-height: 3rem;
  }

  img {
    width: 100%;
  }

  h2 {
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
    padding-top: 5rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    padding: 3rem 0 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    padding: 3rem 0 2rem;
  }

  ul,
  ol {
    padding-left: 8rem;
    list-style: unset;
  }

  ol {
    list-style: decimal;
  }

  a {
    color: ${({ theme }) => theme.color.cyan_600};
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 5rem;
`

Lowlight.registerLanguage("python", python)

const renderer = {
  code(snippet) {
    return <Lowlight className="hljs" language="python" value={snippet} />
  },
}

const PostContent = ({ content, readingTime }) => {
  const contentRef = useRef()
  const { setProgress, setIntersectionQueue, onIntersectIn, onIntersectOut } = usePostContext()

  const fixImageSrc = () => {
    const images = [...contentRef.current.querySelectorAll("img")]
    images.forEach(DOMElement => {
      const [newSCR] = DOMElement.src.match(/^(.*?)\/uploads/)
      // eslint-disable-next-line no-param-reassign
      DOMElement.src = DOMElement.src.replace(newSCR, `${process.env.GATSBY_API_URL}/uploads`)
    })
  }

  const prepareHeadings = () => {
    const headings = [...contentRef.current.querySelectorAll("h2")]
    const intersectionElements = []
    headings.forEach(DOMElement => {
      const id = DOMElement.innerText.toUpperCase()
      // eslint-disable-next-line no-param-reassign
      DOMElement.id = id
      intersectionElements.push(id)
    })
    setIntersectionQueue(intersectionElements)
    headings.forEach(DOMElement => {
      const id = DOMElement.innerText.toUpperCase()
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.intersectionRatio === 1) {
              onIntersectIn(id, intersectionElements)
            } else if (entry.intersectionRect.top) {
              onIntersectOut()
            }
          })
        },
        { threshold: 1 }
      )
      observer.observe(DOMElement)
    })
  }

  const getPostHeight = useCallback(() => {
    if (contentRef.current) return contentRef.current.getBoundingClientRect().height
    return null
  }, [contentRef])

  const updateProgress = () => {
    const ratio = window.scrollY / getPostHeight()
    const progress = ratio > 1 ? 1 : ratio
    setProgress(progress)
  }

  useEffect(() => {
    if (contentRef.current) {
      fixImageSrc()
      prepareHeadings()
      updateProgress()
    }
  }, [contentRef])

  useEffect(() => {
    const throttledUpdateProgress = throttle(updateProgress, 150)
    window.addEventListener("scroll", throttledUpdateProgress)
    return () => window.removeEventListener("scroll", throttledUpdateProgress)
  }, [contentRef])

  return (
    <div>
      <StyledPostReadingTimeWrapper>
        <PostReadingTime readingTime={readingTime} />
      </StyledPostReadingTimeWrapper>
      <StyledContent ref={contentRef}>
        <Markdown langPrefix="hljs" renderer={renderer} value={content} />
      </StyledContent>
      <StyledButtonWrapper>
        <Button aria-label="Back to blog" to={urls.blog.url}>
          Back
        </Button>
      </StyledButtonWrapper>
    </div>
  )
}

PostContent.defaultProps = {}

PostContent.propTypes = {}

export default memo(PostContent)
