import React from "react"

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

// import fbLogo from "assets/svg/logo_fb_white.svg"
// import inLogo from "assets/svg/logo_in_white.svg"

const StyledHeader = styled.header`
  z-index: 1;
  padding: 12.5rem 0 3rem 0;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.85);
  }

  ${({ theme }) => theme.mq.s} {
    padding: 11rem 0 3rem 0;
  }

  ${({ theme }) => theme.mq.xs} {
    padding: 11rem 0 3rem 0;
  }
`

const StyledBackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  z-index: -1;
`

const StyledTitle = styled.h1`
  font-size: 3.6rem;
  line-height: 4.4rem;
  color: ${({ theme }) => theme.color.text.inverted};
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  max-width: 820px;
  margin: 0 auto;

  ${({ theme }) => theme.mq.s} {
    font-size: 3rem;
    line-height: 3rem;
  }

  ${({ theme }) => theme.mq.xs} {
    font-size: 2.8rem;
    line-height: 2.8rem;
    padding-left: 2rem;
  }
`

const StyledPostInfo = styled.div`
  position: relative;
  max-width: 820px;
  margin: 0 auto;
  color: ${({ theme }) => theme.color.text.inverted};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 4rem;

  ${({ theme }) => theme.mq.xs} {
    padding-left: 2rem;
  }
`

const StyledAuthorInfo = styled.p`
  font-weight: 600;
  font-size: 1.2rem;

  ${({ theme }) => theme.mq.xs} {
    margin-bottom: 2rem;
  }
`

const StyledCategory = styled.span`
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ color }) => color};
  background-color: ${({ theme }) => theme.color.white_000};
  min-width: 75px;
  padding: 0.5rem 2.5rem;
  text-align: center;
  margin-bottom: 10px;
`

const StyledDivider = styled.span`
  padding: 0 10px;
`

// const StyledSocialMedia = styled.div`
//   display: flex;
// `

// const StyledFbLogo = styled(fbLogo)`
//   display: block;
//   margin-right: 15px;
//   width: 100%;
//   max-width: 30px;
//   max-height: 30px;
// `

// const StyledInLogo = styled(inLogo)`
//   display: block;
//   width: 100%;
//   max-width: 30px;
//   max-height: 30px;
// `

const PostHeader = ({ author, backgroundImage, category, title }) => (
  <StyledHeader color={category.color}>
    <StyledBackgroundImage
      image={getImage(backgroundImage)}
      imgStyle={{ objectFit: "fixed" }}
      style={{ position: "absolute" }}
    />
    <StyledTitle>{title}</StyledTitle>
    <StyledPostInfo>
      <StyledAuthorInfo>
        <StyledCategory color={category.color}>{category.Name}</StyledCategory>
        <br />
        <span>{`${author.First_name} ${author.Last_name}`}</span>
        <StyledDivider>|</StyledDivider>
        <span>{author.Position}</span>
      </StyledAuthorInfo>
      {/* <StyledSocialMedia>
        <a href="/">
          <StyledFbLogo />
        </a>
        <a href="/">
          <StyledInLogo />
        </a>
      </StyledSocialMedia> */}
    </StyledPostInfo>
  </StyledHeader>
)

export default PostHeader
