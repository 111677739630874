import React from "react"

import styled from "styled-components"

import { usePostContext } from "context/PostContext"

const StyledHeading = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  padding: 3rem 0;
  font-size: 1.4rem;
`
const StyledList = styled.ol`
  list-style: decimal inside;
`
const StyledNavElement = styled.li`
  font-size: 1.4rem;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  transform: ${({ active }) => (active ? "translateX(10px)" : "translateX(0px)")};
  transition: 0.25s;
  margin-bottom: 2rem;

  & a {
    color: ${({ theme }) => theme.color.text.default};
  }
`

const PostTableOfContent = () => {
  const { intersectionQueue, cursor } = usePostContext()
  return (
    <nav>
      <StyledHeading>contents:</StyledHeading>
      <StyledList>
        {intersectionQueue.map((anchor, index) => (
          <StyledNavElement active={cursor === index}>
            <a href={`#${anchor}`}>{anchor}</a>
          </StyledNavElement>
        ))}
      </StyledList>
    </nav>
  )
}

export default PostTableOfContent
