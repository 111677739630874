import React from "react"

import styled from "styled-components"

import IconClock from "assets/svg/blog/clock.svg"

import { usePostContext } from "../../../context/PostContext"
// import fbLogo from "assets/svg/logo_fb.svg"
// import inLogo from "assets/svg/logo_in.svg"
// import { usePostContext } from "context/PostContext"

const StyledWrapper = styled.div`
  align-self: stretch;
`

const StyledIconClock = styled(IconClock)`
  width: 2rem;
  path {
    fill: ${({ theme }) => theme.color.text.default};
  }
`

const StyledText = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  justify-content: center;
  padding: 1rem 0 2rem;
`

const StyledBar = styled.div`
  height: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.gradient.linear.cyan_light_to_purple};
  position: relative;

  &::after {
    transition: 0.35s;
    content: "";
    position: absolute;
    display: block;
    height: 100%;
    width: ${({ unread }) => unread * 100}%;
    background-color: ${({ theme }) => theme.color.gray_200};
    right: 0;
    top: 0;
    z-index: 1;
  }
`

// const StyledSocialMedia = styled.div`
//   display: flex;
//   margin: 20px 0;
//   justify-content: center;
// `

// const StyledFbLogo = styled(fbLogo)`
//   display: block;
//   margin-right: 15px;
//   width: 100%;
//   max-width: 30px;
//   max-height: 30px;
// `

// const StyledInLogo = styled(inLogo)`
//   display: block;
//   width: 100%;
//   max-width: 30px;
//   max-height: 30px;
// `

const PostReadingTime = ({ readingTime }) => {
  const { progress } = usePostContext()

  return (
    <StyledWrapper>
      <StyledText>
        <StyledIconClock />
        {`${readingTime} minutes read`}
      </StyledText>
      <StyledBar unread={1 - progress} />
      {/* <StyledSocialMedia>
      <a href="/">
        <StyledFbLogo />
      </a>
      <a href="/">
        <StyledInLogo />
      </a>
    </StyledSocialMedia> */}
    </StyledWrapper>
  )
}

export default PostReadingTime
