import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Text from "components/common/Text"

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.graphite};
  padding: 3rem;
  color: ${({ theme }) => theme.color.text.inverted};
  display: grid;
  grid-gap: 2rem;
  justify-items: stretch;
`

const StyledTileHeader = styled.div`
  display: grid;
  grid-gap: 2rem;

  ${({ theme }) => theme.mq.m} {
    grid-template-columns: auto 1fr;
    margin-bottom: 2rem;
  }
`
const StyledAvatar = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  justify-self: center;

  ${({ theme }) => theme.mq.s} {
    width: 130px;
    height: 130px;
  }
`

const StyledBaseInfo = styled.div`
  position: relative;
  ${({ theme }) => theme.mq.m} {
    display: flex;
    align-items: flex-end;
    padding: 0 0.5rem;
  }
`

const StyledName = styled.div`
  text-align: center;
  ${({ theme }) => theme.mq.m} {
    text-align: left;
  }

  p {
    text-align: center;
  }
`

const StyledBio = styled.p`
  font-size: 1.4rem;
`

const AuthorCard = ({ avatar, bio, firstName, lastName, position }) => (
  <StyledWrapper>
    <StyledTileHeader>
      <StyledAvatar>
        <GatsbyImage
          image={getImage(avatar.localFile)}
          imgStyle={{ objectFit: "cover" }}
          style={{ height: "100%", width: "100%" }}
        />
      </StyledAvatar>
      <StyledBaseInfo>
        <StyledName>
          <Text bold fontSize="1.6rem" inverted uppercase>{`${firstName} ${lastName}`}</Text>
          <Text fontSize="1.4rem" inverted uppercase>
            {position}
          </Text>
        </StyledName>
      </StyledBaseInfo>
    </StyledTileHeader>
    <StyledBio>{bio}</StyledBio>
  </StyledWrapper>
)

export default AuthorCard
