import React from "react"

import styled from "styled-components"

import AuthorCard from "components/common/AuthorCard"

const StyledWrapper = styled.div`
  align-self: stretch;
  justify-self: flex-start;
  width: 100%;
  max-width: 380px;
  padding-right: 2rem;
  padding-top: 5rem;

  ${({ theme }) => theme.mq.m} {
    max-width: unset;
    padding: 0;
    grid-column: 1/3;
    margin-top: 4rem;
  }

  ${({ theme }) => theme.mq.xs} {
    grid-column: unset;
  }
`

const StyledStickyBox = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.size.header}px;
`

const PostAuthorInfo = ({ First_name: firstName, Avatar: avatar, Last_name: lastName, Position: position, bio }) => (
  <StyledWrapper>
    <StyledStickyBox>
      <AuthorCard avatar={avatar} bio={bio} firstName={firstName} lastName={lastName} position={position} />
    </StyledStickyBox>
  </StyledWrapper>
)

export default PostAuthorInfo
