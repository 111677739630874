import React from "react"

import styled from "styled-components"

import PostReadingTime from "./PostReadingTime"
import PostTableOfContent from "./PostTableOfContent"

const StyledWrapper = styled.div`
  align-self: stretch;
  justify-self: flex-end;
  width: 100%;
  max-width: 380px;
  padding-left: 2rem;
  padding-top: 5rem;

  ${({ theme }) => theme.mq.xs} {
    display: none;
  }
`

const StyledStickyBox = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.size.header + 30}px;
`

const PostGeneralInfo = ({ readingTime }) => (
  <StyledWrapper>
    <StyledStickyBox>
      <PostReadingTime readingTime={readingTime} />
      <PostTableOfContent />
    </StyledStickyBox>
  </StyledWrapper>
)

export default PostGeneralInfo
